import { useLocation, useNavigate} from 'react-router-dom';
import './assets/css/shared.scss';

import 'primeicons/primeicons.css';
import {PrimeReactProvider} from 'primereact/api';
import 'primeflex/primeflex.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import {history} from './helpers/history';
import AppRoutes from "./routes/app-routes";
import React, {Suspense} from "react";
import  {
    ReactFlowProvider,
} from 'reactflow';

export {App};

function App() {
    history.navigate = useNavigate();
    history.location = useLocation();

    return (
        <React.StrictMode>
            <ReactFlowProvider>
                <PrimeReactProvider>

                    <div className="app-container bg-light">
                        <Suspense fallback={<></>}>
                            <AppRoutes/>
                        </Suspense>
                    </div>
                </PrimeReactProvider>
            </ReactFlowProvider>
        </React.StrictMode>
    );
}